import * as config from '../../../constant'

function getSubCategoryReducer(
    state = {
        apiData: [],
        fetching: false,
        fetched: false,
        error: null,
    },
    action
) {
    switch (action.type) {
        case config.HC_SUB_CATEGORY_LIST_REQUEST: {
            return { ...state, fetching: true, fetched: false, error: null };
        }
        case config.HC_SUB_CATEGORY_LIST_FAIL: {
            return {
                ...state,
                fetching: false,
                error: action.payload,
                fetched: true,
            };
        }
        case config.HC_SUB_CATEGORY_LIST_SUCCESS: {
            return {
                state,
                error: null,
                fetching: false,
                fetched: true,
                apiData: action.payload,
            };
        }
        default:
            return state;
    }
}

export {
    getSubCategoryReducer,
};
