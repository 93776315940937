import { combineReducers } from "redux";
import { getTeamsReducer } from "./TeamReducer";
import { getHospitalLocationReducer } from "./HospitalLocationReducer";
import { getTeamResourceReducer } from './TeamResourceReducer';
import { getSubCategoryReducer } from './SubCategoryReducer';
import { getHCCategoryReducer, getHCHospitalListReducer, getHCCategoryUpdateReducer, postHCAddCategoryReducer } from './CategoryReducer';
import { getCountryReducer, getStateReducer, getCityReducer, getPincodeReducer, getCountryFilterReducer, getStateFilterReducer, getCityFilterReducer } from "./CountryReducer";

const rootReducers = combineReducers({
    getHCCategoryReducer:           getHCCategoryReducer, 
    getHCHospitalListReducer:       getHCHospitalListReducer, 
    getHCCategoryUpdateReducer:     getHCCategoryUpdateReducer,
    postHCAddCategoryReducer:       postHCAddCategoryReducer,
    getTeamsReducerData :           getTeamsReducer,
    getHospitalLocationReducerData: getHospitalLocationReducer,
    getTeamResourceReducerData:     getTeamResourceReducer,
    getSubCategoryReducerData:      getSubCategoryReducer,
    getCountryReducerData:          getCountryReducer,
    getCountryFilterReducerData:    getCountryFilterReducer,
    getStateReducerData:            getStateReducer,
    getStateFilterReducerData:      getStateFilterReducer,
    getCityReducerData:             getCityReducer,
    getCityFilterReducerData:       getCityFilterReducer,
    getPincodeReducerData:          getPincodeReducer,

});

export default rootReducers;