import * as GlobalConfig from '../../../constant'
const initialstate = {};

function getHCCategoryReducer(
  state = {
    apiData: initialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case GlobalConfig.HC_CATEGORY_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case GlobalConfig.HC_CATEGORY_LIST_FAIL: {
      return {
        ...state,
        fetching: false,
        error: action.payload,
        fetched: true,
      };
    }
    case GlobalConfig.HC_CATEGORY_LIST_SUCCESS: {
      return {
        ...state,
        error: null,
        fetching: false,
        fetched: true,
        apiData: action.payload,
      };
    }
    default:
      return state;
  }
}
/*--------- hospital list----*/
function getHCHospitalListReducer(
  state = {
    apiData: initialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case GlobalConfig.HC_HOSPITAL_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case GlobalConfig.HC_HOSPITAL_LIST_FAIL: {
      return {
        ...state,
        fetching: false,
        error: action.payload,
        fetched: true,
      };
    }
    case GlobalConfig.HC_HOSPITAL_LIST_SUCCESS: {
      return {
        ...state,
        error: null,
        fetching: false,
        fetched: true,
        apiData: action.payload,
      };
    }
    default:
      return state;
  }
}
/*--------- Update category----*/
function getHCCategoryUpdateReducer(
  state = {
    getHCCategoryUpdateListdata: initialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case GlobalConfig.HomeCareUpdate_CATEGORY_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case GlobalConfig.HomeCareUpdate_CATEGORY_FAIL: {
      return {
        ...state,
        fetching: false,
        error: action.payload,
        fetched: true,
      };
    }
    case GlobalConfig.HomeCareUpdate_CATEGORY_SUCCESS: {
      return {
        ...state,
        error: null,
        fetching: false,
        fetched: true,
        getHCCategoryUpdateListdata: action.payload,
      };
    }
    default:
      return state;
  }
}
/*--------- Add category----*/
function postHCAddCategoryReducer(
  state = {
    postHCAddCategoryData: initialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case GlobalConfig.HomeCareAdd_CATEGORY_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case GlobalConfig.HomeCareAdd_CATEGORY_FAIL: {
      return {
        ...state,
        fetching: false,
        error: action.payload,
        fetched: true,
      };
    }
    case GlobalConfig.HomeCareAdd_CATEGORY_SUCCESS: {
      return {
        ...state,
        error: null,
        fetching: false,
        fetched: true,
        postHCAddCategoryData: action.payload,
      };
    }
    default:
      return state;
  }
}
export {
  getHCCategoryReducer,
  getHCHospitalListReducer,
  getHCCategoryUpdateReducer,
  postHCAddCategoryReducer,
};
